import { useEffect, useState } from "react"
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroup,
} from '@elastic/eui';

import { storage } from "./helpers/firebase"

const App = () => {
  const [apps, setApps] = useState();

  useEffect(() => {
    const fetchFiles = async () => {
      const folders = await storage.ref("apps").listAll();

      const promises = folders.prefixes.map(async prefix => {
        const list = await prefix.listAll();

        const promises = list.items.map(async item => {
          const url = await item.getDownloadURL();
          const metadata = await item.getMetadata();

          return { url, metadata, name: item.name };
        })

        const files = await Promise.all(promises);

        const images = files.filter(file => typeof file.metadata.contentType === "string" && file.metadata.contentType.startsWith("image/"));
        const image = images.length > 0 ? images[0] : null;

        return { name: prefix.name, files, image };
      })

      const apps = await Promise.all(promises);

      setApps(apps);
    }

    fetchFiles();
  }, [])

  return (
    <EuiPage>
      <EuiPageBody component="div">
        <EuiPageContent>
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>My Apps</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiFlexGroup>
              {Array.isArray(apps) && apps.map(app => (
                <EuiFlexItem key={app.name} grow={false} >
                  {app.image && (
                    <EuiImage
                      allowFullScreen
                      size="s"
                      url={app.image.url}
                      alt={app.image.name}
                    />
                  )}

                  <EuiTitle style={{ textAlign: "center" }} ><h3>{app.name}</h3></EuiTitle>

                  <EuiListGroup
                    flush
                    listItems={app.files.map(file => ({
                      label: file.name,
                      href: file.url,
                    }))}
                  />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>

  );
}

export default App;
