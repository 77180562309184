import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD4xnjZ7cQtZx7MjIf9Zu8s9gfCVH9YCpc",
    authDomain: "my-apps-adaff.firebaseapp.com",
    projectId: "my-apps-adaff",
    storageBucket: "my-apps-adaff.appspot.com",
    messagingSenderId: "285493397771",
    appId: "1:285493397771:web:7af98bb3f0616cf944fa0e"
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

// const db = firebaseApp.firestore();
// const auth = firebaseApp.auth();
// const functions = firebaseApp.functions("europe-west2");
const storage = firebaseApp.storage();

export {
    // db,
    // auth,
    // functions,
    firebase,
    storage,
};
